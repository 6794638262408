import React ,{useState,useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { connect, } from 'react-redux';
import { Field, reduxForm,getFormValues,change,registerField } from 'redux-form'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomCheckBox from 'components/CustomCheckBox/CustomCheckBox'
import dayjs from 'dayjs'
import FormBeneficiaire from './FormBeneficiaire';
import {Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
const useStyles = makeStyles(theme => ({
    bcontainer:{
      paddingTop:10,
      backgroundColor:'transparent',
      borderRadius:0
    },
    bPaper:{
      padding:10,
      borderRadius:10
    },
    btnContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:20
    },
    btnback:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:"#f9b233",
      paddingRight:20,
      paddingLeft:20,
      paddingTop:10,
      paddingBottom:10,
      color:'#ffffff',
      "&:hover":{
        backgroundColor:"#3c3c3b",
      },
      borderRadius:10,
    },
    btnnext:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:theme.palette.primary.main,
      paddingRight:20,
      paddingLeft:20,
      paddingTop:10,
      paddingBottom:10,
      color:'#ffffff',
      "&:hover":{
        backgroundColor:"#f9b233",
      },
      borderRadius:10,
    },
    bhead:{
      padding:10,
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },
    assureInfoRow:{
      display:'flex',
      justifyContent:'space-between'
    },
  
}));

const styles = theme =>({
  root: {
    backgroundColor:theme.palette.primary.main,
    borderRadius:50,
    border: 1,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginBottom:20,
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
    }
  },
    table: {
        minWidth: 650,
      },
      btnAdd:{
          
      },
      btnAction:{
        width:80,
        height:80,
        backgroundColor:'#fff',
        color:theme.palette.primary.main,
        '&:hover':{
          backgroundColor:theme.palette.primary.main,
          color:theme.palette.primary.contrastText
        }
      },
      btnNext:{
        backgroundColor:theme.palette.primary.main,
        height:40,
        width:150,
        borderRadius:20,
        color:theme.palette.primary.contrastText
      },
      bhead:{
        padding:10
      }
})
function StepBeneficaire(props) {
    const { handleSubmit,data,handleBack,handleNext,steps,activeStep } = props

    const classes = useStyles()
    const [mesBeneficaires,setBeneficaires] = useState([])
    const [open, setOpen] = React.useState(false);
    const [moimemeBeneficiaire, setMoimemeBeneficiaire] = React.useState(false);
    const [modeBeneficaire,setModeBeneficaire] = React.useState('Tous');
    const [modeOpen,setModeOpen] = React.useState('autreBeneficaire')
    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleMoimemeBeneficaire =(mytype=null)=>{
      const moimeme =!moimemeBeneficiaire;
      setMoimemeBeneficiaire(moimeme)
      if(moimeme){
        const currentBeneficiaire ={
          nomBeneficiaire: data.nomSous && data.nomSous, 
          prenomBeneficiaire: data.prenomSous && data.prenomSous,
          dateNaissanceBeneficiaire: data.dateNaissanceSous && data.dateNaissanceSous,
          lieuNaissanceBeneficiaire: data.lieuNaissanceSous && data.lieuNaissanceSous,
          lieuResidenceBeneficiaire: data.lieuResidenceSous && data.lieuResidenceSous,
          filiationBeneficiaire: 'Moi même',
          emailBeneficiaire: data.emailSous && data.emailSous,
          telephoneBeneficiaire: data.mobileSous && data.mobileSous,
          taux:'100',
          monIndex: (mesBeneficaires.length+1),
          type:mytype
        } 
        let newbeneficiaires =  mesBeneficaires
        newbeneficiaires =[...newbeneficiaires,currentBeneficiaire]
        props.updateField('beneficiaires',newbeneficiaires)
        setBeneficaires(newbeneficiaires)
      }else{
        setModeOpen('autreBeneficiaire')
      }
    }

    const handleAddAssure =(mesdonne)=>{
      if(mesdonne){
        mesdonne.type =modeBeneficaire
        let newbeneficiaires = mesBeneficaires
        newbeneficiaires= [...newbeneficiaires,mesdonne]
        props.updateField('beneficiaires',newbeneficiaires)
        setBeneficaires(newbeneficiaires)
        handleClose()
      }  
    }
    const deletebeneficaire =(assure)=>{
        let crurrentAsuree = mesBeneficaires   
        crurrentAsuree =  crurrentAsuree.filter((item=>item.monIndex!==assure.monIndex))
        props.updateField('beneficiaires',crurrentAsuree)
        setBeneficaires(crurrentAsuree)
    }
     
    useEffect(() => {
      if(!data.hasOwnProperty('beneficiaires')){
        props.dispatch(registerField("adForm",'beneficiaires','Field'));
        props.updateField('beneficiaires',mesBeneficaires)
      }else{
        if(mesBeneficaires.length===0){
          setBeneficaires(data.beneficiaires)
        }
      }
    }, [mesBeneficaires])

    const handleClickTypeBenef =(event,type)=>{
      const {value} = event.target
      if(data.hasOwnProperty('beneficiaireauterme') && Array.isArray(data.beneficiaireauterme)){
        if(value==='autre' && !data.beneficiaireauterme.includes('autre') && type==='AUTERME'){
          handleOpen()
          setModeBeneficaire('AUTERME')
        }
      }
      if(data.hasOwnProperty('beneficiaireaudeces') && Array.isArray(data.beneficiaireaudeces)){
        if(value==='autre' && !data.beneficiaireaudeces.includes('autre') && type==='AUDECES'){
        handleOpen()
        setModeBeneficaire('AUDECES')
        }
        if(value==='adherent' && !data.beneficiaireaudeces){
          handleMoimemeBeneficaire('AUDECES')
        }else{
          if(value==='adherent' &&  !data.beneficiaireaudeces.includes('adherent')){
            handleMoimemeBeneficaire('AUDECES')
          }
        }
      }
      if(value==='adherent' && !data.beneficiaireauterme){
        handleMoimemeBeneficaire('AUTERME')
      }else{
        if(value==='adherent' &&  !data.beneficiaireauterme.includes('adherent')){
          handleMoimemeBeneficaire('AUTERME')
        }
      }
    }

    const renderBeneficiaire =(props)=>{

      return( props.input.value.length!==0? (
         <TableContainer component={Paper}>
           <Table className={classes.table} aria-label="simple table">
             <TableHead>
               <TableRow>
                 <TableCell align="center" >Nom</TableCell>
                 <TableCell align="center" >Prénoms</TableCell>
                 <TableCell align="center">Né(e) le</TableCell>
                 <TableCell align="center">Lieu de naissance</TableCell>
                 <TableCell align="center">Lieu de residence</TableCell>
                 <TableCell align="center">Filiation</TableCell>
                 <TableCell align="center">Téléphone</TableCell>
                 <TableCell align="center">Email</TableCell>
                 <TableCell align="center">Taux (%)</TableCell>
                 <TableCell align="center">Actions</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
             {props.input.value.map(assure => (
                 <TableRow key={assure.nomBeneficiaire}>
                   <TableCell align="center" component="th" scope="row">
                     {assure.nomBeneficiaire}
                   </TableCell>
                   <TableCell align="center">{assure.prenomBeneficiaire}</TableCell>
                   <TableCell align="center">{assure.dateNaissanceBeneficiaire?dayjs(assure.dateNaissanceBeneficiaire).format('DD/MM/YYYY'):null}</TableCell>
                   <TableCell align="center">{assure.lieuNaissanceBeneficiaire}</TableCell>
                   <TableCell align="center">{assure.lieuResidenceBeneficiaire}</TableCell>
                   <TableCell align="center">{assure.filiationBeneficiaire}</TableCell>
                   <TableCell align="center">{assure.telephoneBeneficiaire}</TableCell>
                   <TableCell align="center">{assure.emailBeneficiaire}</TableCell>
                   <TableCell align="center">{assure.taux}</TableCell>
                   <TableCell align="center">
                   <IconButton
                       data-id={assure.numeroCniAssure}
                       aria-label="actions"
                       aria-haspopup="true"
                       onClick={()=>deletebeneficaire(assure)}
                     >
                     <DeleteIcon />
                     </IconButton>
                   </TableCell>
                 </TableRow>
               ))}
             </TableBody>
           </Table>
       </TableContainer>):((!data.beneficiaireaudeces && !data.beneficiaireauterme)?<Typography>Aucun bénéficiaire </Typography>:null)
      )}
  return (
    <div>
      <FormBeneficiaire 
        handleClose={handleClose} 
        handleOpen={handleOpen} 
        open={open} 
        handleAddAssure={handleAddAssure} 
      />
      <Paper className={classes.bcontainer} variant="outlined">
          <div className={classes.bhead}>
              <Typography variant='h5'>Liste des bénéficiaires</Typography>
          </div>
          <Paper variant="outlined">
          <GridContainer>

          {(data.produit.CodeProduit!=='LFFUN',data.produit.CodeProduit!=='LPREVO')&&
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <Field
                    label="Au terme du contrat"
                    id="beneficiaireauterme"
                    name="beneficiaireauterme"
                    options={[
                      {"label":"L'adherent","value":"adherent"},
                      {"label":"Le conjoint non divorcé, ni séparé de corps","value":"conjoint"},
                      {"label":"Les enfants nés et à naître","value":"enfants"},
                      {"label":"Autres, Préciser","value":"autre"}
                    ]}
                    formControlProps={{
                        fullWidth: true
                    }}
                    component={CustomCheckBox}
                    onClick={(e)=>handleClickTypeBenef(e,'AUTERME')}
                />
              </GridItem>} 
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <Field
                    label="En cas de décès avant le terme"
                    id="beneficiaireaudeces"
                    name="beneficiaireaudeces"
                    options={(data.produit.CodeProduit==='YKV_2004',data.produit.CodeProduit==='LFFUN',data.produit.CodeProduit==='LPREVO')?[
                      {"label":"L'adherent","value":"adherent"},
                      {"label":"Le conjoint non divorcé, ni séparé de corps","value":"conjoint"},
                      {"label":"Les enfants nés et à naître","value":"enfants"},
                      {"label":"Autres, Préciser (ajouter des bénéficiaires) ","value":"autre"}
                    ]:[
                      {"label":"Le conjoint non divorcé, ni séparé de corps","value":"conjoint"},
                      {"label":"Les enfants nés et à naître","value":"enfants"},
                      {"label":"Autres, Préciser (ajouter des bénéficiaires) ","value":"autre"}
                    ]}
                    formControlProps={{
                        fullWidth: true
                    }}
                    component={CustomCheckBox}
                    onClick={(e)=>handleClickTypeBenef(e,'AUDECES')}
                />
              </GridItem>
            </GridContainer>
          </Paper>
          <Field 
            name="beneficiaires"
            component={renderBeneficiaire}
        /> 
      </Paper>
      <div className={classes.btnContainer}>
          <Button onClick={handleBack} className={classes.btnback}>Retour</Button>
          <Button type='submit' disabled={ (data.hasOwnProperty('beneficiaires') && data.beneficiaires.length===0)} onClick={handleNext}  className={classes.btnnext}>Continuer</Button>
      </div>
      
    </div>
  )
}

  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    updateField
  }
  const mapStateProps =(state)=>({
    data: getFormValues('adForm')(state),
  })

  StepBeneficaire =withStyles(styles)(StepBeneficaire)
  
  export default connect(mapStateProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false})(StepBeneficaire))

