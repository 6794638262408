import React, { useEffect, useState  } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import axios from 'axios';

const UpdateProposition = () => {
  
  useEffect(() => {
    $('#myTable').DataTable(); // Initialisez DataTable une fois que les données sont chargées
  }, []);


  return (
    <div className='flex flex-col px-4'>
      <h1>Rechercher une proposition</h1>
      <table id="myTable" className='table table-bordered table-striped'>
        <thead>
          <tr>
            <th>Code</th>
            <th>Souscripteur</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
            <tr >
              <td>civi 1</td>
              <td>sexe 2</td>
              <td>email 3</td>
            </tr>
            <tr >
              <td>civi 1</td>
              <td>sexe 2</td>
              <td>email 3</td>
            </tr>
            <tr >
              <td>civi 6</td>
              <td>sexe 6</td>
              <td>email 8</td>
            </tr>
            <tr >
              <td>civi 10</td>
              <td>sexe 20</td>
              <td>email 30</td>
            </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UpdateProposition;
