import React, { useCallback, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDate from 'components/CustomDate/CustomDate';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput';
import { IconButton, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import dayjs from 'dayjs'
import {connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { reduxForm, change, getFormValues,registerField} from 'redux-form';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline';
const initialState ={
    capitalconnexe:0,
    civiliteSous:"",
    nomSous:"",
    prenomSous:"",
    datenaissance:"",
    lieuNaissanceSous:"",
    naturePiece:"",
    pieceIdentiteSous:"",
    lieuResidenceSous:"",
    adressePostaleSous:"",
    emailSous:"",
    mobileSous:"",
    mobile2Sous:"",
    telephoneSous:"",
    professionSous:"",
    employeur:"",
    personneressource:"",
    contactpersonneressource:"",
    personneressource2:"",
    contactpersonneressource2:""
}
const ModAdherent = ({open, handleClose,villes,mode,setMode, professions,data,indice,handleSetData, societes, civilites,secteurActivites,...resProps}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [inputField,setInputField] =useState({})
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const handleChangePhone = (name,value) =>{
        setInputField({...inputField,[name]:value})
    }
const handleAddDate = (e)=>{
    //e.preventDefault()
    const newdata =[...data]
    newdata[indice]={
        capitalconnexe:inputField['civiliteSous'] || 0,
        civiliteSous:inputField['civiliteSous'] || "",
        nomSous:inputField['nomSous'] || "",
        prenomSous:inputField['prenomSous'] || "",
        datenaissance:inputField['datenaissance'] || "",
        lieuNaissanceSous:inputField['lieuNaissanceSous'] || "",
        naturePiece:inputField['naturePiece'] || "",
        pieceIdentiteSous:inputField['pieceIdentiteSous'] || "",
        lieuResidenceSous:inputField['lieuResidenceSous'] || "",
        adressePostaleSous:inputField['adressePostaleSous'] || "",
        emailSous:inputField['emailSous'] || "",
        mobileSous:inputField['mobileSous'] || "",
        mobile2Sous:inputField['mobile2Sous'] || "",
        telephoneSous:inputField['telephoneSous'] || "",
        professionSous:inputField['professionSous'] || "",
        employeur:inputField['employeur'] || "",
        personneressource:inputField['personneressource'] || "",
        contactpersonneressource:inputField['contactpersonneressource'] || "",
        personneressource2:inputField['personneressource2'] || "",
        contactpersonneressource2:inputField['contactpersonneressource2'] || "",
}
    handleSetData(newdata)
    setMode('MOD')
    handleClose()
}
const initialiseFields = useCallback(()=>{
    if(mode === 'ADD'){
        setInputField(initialState)
    }
    if(mode === 'MOD'){
        setInputField({
            capitalconnexe:data[indice]['capitalconnexe'] || 0,
            civiliteSous:data[indice]['civiliteSous'] ||"",
            nomSous:data[indice]['nomSous'] ||"",
            prenomSous:data[indice]['prenomSous'] ||"",
            datenaissance:data[indice]['datenaissance'] ||"",
            lieuNaissanceSous:data[indice]['lieuNaissanceSous'] ||"",
            naturePiece:data[indice]['naturePiece'] ||"",
            pieceIdentiteSous:data[indice]['pieceIdentiteSous'] ||"",
            lieuResidenceSous:data[indice]['lieuResidenceSous'] ||"",
            adressePostaleSous:data[indice]['adressePostaleSous'] ||"",
            emailSous:data[indice]['emailSous'] ||"",
            mobileSous:data[indice]['mobileSous'] ||"",
            mobile2Sous:data[indice]['mobile2Sous'] ||"",
            telephoneSous:data[indice]['telephoneSous'] ||"",
            professionSous:data[indice]['professionSous'] ||"",
            employeur:data[indice]['employeur'] ||"",
            personneressource:data[indice]['personneressource'] ||"",
            contactpersonneressource:data[indice]['contactpersonneressource'] ||"",
            personneressource2:data[indice]['personneressource2'] ||"",
            contactpersonneressource2:data[indice]['contactpersonneressource2'] ||""
        })
    }
},[mode])
useEffect(()=>{
    initialiseFields()
},[mode])
  return (
    <Dialog fullScreen={fullScreen} maxWidth='md' open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <div className='flex flex-row items-center justify-between'>
            <DialogTitle id="form-dialog-title">Ajout d'un adhérent</DialogTitle>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </div>
        <Divider />
        
        <DialogContent>
            <form onSubmit={handleAddDate} onReset={handleClose} className='overflow-auto'>
                <div className='mx-auto max-w-screen-sm'>
                    <Paper variant='outlined' className='my-2 p-2' >
                        <div className='py-2'>
                            <CustomInput
                                required
                                label="Part de l'adherent (F CFA)"
                                id="capitalconnexe"
                                name="capitalconnexe"
                                variant="outlined"
                                formControlProps={{
                                    fullWidth: true,
                                    type:'number'
                                }}
                                InputProps={{
                                    min:0,
                                    max:30000000,
                                    type:'number'
                                }}
                                autoFocus
                                onChange ={handleChange}
                                value={inputField['capitalconnexe'] || ""}
                            />
                        </div>
                        <CustomRadio
                            required
                            label="Civilité *"
                            value={inputField['civiliteSous'] || ""}
                            id="civiliteSous"
                            name='civiliteSous'
                            options={[{ "label": "Mme", "value": "Mme" }, { "label": "Mlle", "value": "Mlle" }, { "label": "M", "value": "M" }]}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange ={handleChange}
                            row
                        />
                        <CustomInput
                            required
                            label="Nom"
                            id="nomSous"
                            name="nomSous"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            autoFocus
                            onChange ={handleChange}
                            value={inputField['nomSous'] || ""}
                        />
                        <CustomInput
                            required
                            label="Prénom"
                            id="prenomSous"
                            name="prenomSous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            variant="outlined"
                            autoFocus
                            onChange ={handleChange}
                            value={inputField['prenomSous'] || ""}
                        />
                        <CustomDate
                            required
                            label="Date de naissance"
                            id="datenaissance"
                            name="datenaissance"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            InputProps={{
                                type: 'date',
                                inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-65, 'year').format('YYYY-MM-DD') },
                            }}
                            variant="outlined"
                            value={inputField['datenaissance'] || ""}
                            onChange ={handleChange}
                        />
                        {(villes&&villes.length!==0)&&<CustomSelect
                            required
                            label="Lieu de naissance"
                            id="lieuNaissanceSous"
                            name="lieuNaissanceSous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            options={villes}
                            onChange ={handleChange}
                            value={inputField['lieuNaissanceSous'] || ""}
                        />}
                        
                        <CustomRadio
                            required
                            label="Nature de la pièce *"
                            id="naturePiece"
                            name='naturePiece'
                            options={[{ "label": "CNI", "value": "CNI" }, { "label": "Attestation", "value": "AT" }, { "label": "Passeport", "value": "Passport" }, { "label": "Autre pièce", "value": "Autre" }]}
                            formControlProps={{
                                fullWidth: true
                            }}
                            row
                            onChange ={handleChange}
                            value={inputField['naturePiece'] || ""}
                        />
                        <CustomInput
                            required
                            label="N° pièce d'identité"
                            name='pieceIdentiteSous'
                            id="pieceIdentiteSous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            variant="outlined"
                            onChange ={handleChange}
                            value={inputField['pieceIdentiteSous'] || ""}
                        />
                    </Paper>
                    <Paper variant='outlined' className='my-2 p-2' >
                    {(villes&&villes.length!==0)&&<CustomSelect
                            required
                            label="Lieu de résidence"
                            id="lieuResidenceSous"
                            name='lieuResidenceSous'
                            formControlProps={{
                                fullWidth: true
                            }}
                            options={villes}
                            onChange ={handleChange}
                            value={inputField['lieuResidenceSous'] || ""}
                        />}
                        
                        <CustomInput
                            label="Adresse postale"
                            id="adressePostaleSous"
                            name="adressePostaleSous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            variant="outlined"
                            onChange ={handleChange}
                            value={inputField['adressePostaleSous'] || ""}
                        />
                        <CustomInput
                            label="Adresse email"
                            id="emailSous"
                            name="emailSous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={inputField['emailSous'] || ""}
                            type='email'
                            variant="outlined"
                            onChange ={handleChange}
                        />
                        <CustomPhoneInput
                            required
                            label="Mobile 1 (whatsapp)"
                            id="mobileSous"
                            name="mobileSous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            InputProps={{
                                inputProps: { max:10},
                            }}
                            variant="outlined"
                            onChange ={handleChangePhone}
                            value={inputField['mobileSous'] || ""}
                        />
                        <CustomPhoneInput
                            label="Mobile 2"
                            id="mobile2Sous"
                            name="mobile2Sous"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={inputField['mobile2Sous'] || ""}
                            InputProps={{
                                inputProps: { max:10},
                            }}
                            variant="outlined"
                            onChange ={(v)=>handleChangePhone('mobile2Sous',v)}
                        />
                        
                        <CustomPhoneInput
                            label="Téléphone bureau"
                            id="telephoneSous"
                            name='telephoneSous'
                            formControlProps={{
                                fullWidth: true
                            }}
                            InputProps={{
                                inputProps: { max:10},
                            }}
                            variant="outlined"
                            onChange ={(v)=>handleChangePhone('telephoneSous',v)}
                            value={inputField['telephoneSous'] || ""}
                        />
                    </Paper>
                    <Paper variant='outlined' className='my-2 p-2'>
                        <CustomSelect
                            required
                            label="Activité professionnelle"
                            id="professionSous"
                            name='professionSous'
                            formControlProps={{
                                fullWidth: true
                            }}
                            options={professions}
                            onChange ={handleChange}
                            value={inputField['professionSous'] || ""}
                        />
                        <CustomSelect
                            required
                            label="Secteur d'activité"
                            id="employeur"
                            name='employeur'
                            formControlProps={{
                                fullWidth: true
                            }}
                            options={secteurActivites}
                            onChange ={handleChange}
                            value={inputField['employeur'] || ""}
                        />

                    </Paper>
                    <Paper variant='outlined' className='my-2 p-2'>
                    <LabelledOutline label="Personnes à contacter en cas de besoins">
                        <CustomInput
                            required
                            label="Nom & prénom"
                            id="personneressource"
                            name="personneressource"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange ={handleChange}
                            value={inputField['personneressource'] || ""}
                        />
                        <CustomPhoneInput
                            required
                            label="Contact"
                            id="contactpersonneressource"
                            name="contactpersonneressource"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            InputProps={{
                                inputProps: { max:10},
                            }}
                            onChange ={(v)=>handleChangePhone('contactpersonneressource',v)}
                            value={inputField['contactpersonneressource'] || ""}
                        />
                        <CustomInput
                            required
                            label="Nom & prénom"
                            id="personneressource2"
                            name="personneressource2"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange ={handleChange}
                            value={inputField['personneressource2'] || ""}
                        />
                        <CustomPhoneInput
                            required
                            label="Contact"
                            id="contactpersonneressource2"
                            name="contactpersonneressource2"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            InputProps={{
                                inputProps: { max:10},
                            }}
                            onChange ={(v)=>handleChangePhone('contactpersonneressource2',v)}
                            value={inputField['contactpersonneressource2'] || ""}
                        />
                        </LabelledOutline>
                    </Paper>
                    <Divider />
                    <Paper variant='outlined' className='my-2 p-2'>
                    <div className='flex flex-row justify-between items-center' >
                        <Button type='reset' variant='outlined' className='bg-secondary-main'>Annuler</Button>
                        <Button variant="outlined"  type='submit'  className='bg-primary-main' large>Ajouter</Button>
                    </div>       
                    </Paper>
                </div>
                </form>
            </DialogContent>
        
      </Dialog>
  )
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        ui: state.UI,
        dataForm: getFormValues('addPret')(state),
        initialValues: state.form.addPret.values
    }
};

const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapActionsToProps = (state) => {
    return {
        updateField
    }
}
export default connect(mapStateToProps, mapActionsToProps)(reduxForm({form:'addPret', destroyOnUnmount:false})(ModAdherent))

